import { HTTP } from '../../axios/axios'

async function getAll (lang) {
  try {
    const res = []
    const resEn = await HTTP.get('/news-category', { headers: { 'accept-language': 'en' } })
    const resRu = await HTTP.get('/news-category', { headers: { 'accept-language': 'ru' } })
    const resAz = await HTTP.get('/news-category', { headers: { 'accept-language': 'az' } })
    for (const i in resEn.data) {
      res.push({ en: resEn.data[i].name, ru: resRu.data[i].name, az: resAz.data[i].name, _id: resEn.data[i]._id })
    }
    return res
  } catch (error) {
    console.log(error)
  }
}
async function addOne (newsCategory) {
  const categories = [
    { lang: 'en', name: newsCategory.en },
    { lang: 'az', name: newsCategory.az },
    { lang: 'ru', name: newsCategory.ru }
  ]
  return await HTTP.post('/news-category', { categories })
}
async function update (newsCategory, id) {
  await HTTP.patch('/news-category/' + id, { name: newsCategory.en }, { headers: { 'accept-language': 'en' } })
  await HTTP.patch('/news-category/' + id, { name: newsCategory.az }, { headers: { 'accept-language': 'az' } })
  await HTTP.patch('/news-category/' + id, { name: newsCategory.ru }, { headers: { 'accept-language': 'ru' } })
}
async function deleteOne (newsCategory) {
  return await HTTP.delete('/news-category/' + newsCategory._id)
}
async function deleteMany (arr) {
  return await HTTP.post('/news-category/many', { _id: arr })
}

export default { getAll, deleteMany, addOne, update, deleteOne }
