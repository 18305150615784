<template>
  <div>
    <div class="p-4">
      <Table
        :lang="lang"
        :columnNames="['en','ru','az']"
        :keys="['en','ru','az']"
        @getData="getData(lang)"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import requests from '../services/requests/NewsCategoryRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      requests: requests
    }
  },
  name: 'NewsCategory',
  components: {
    Table
  },
  methods: {
    // gets data from backend
    async getData (lang) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang)
      this.$store.commit('setData', temp)
      this.$emit('loading', false)
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang)
  }
}
</script>
